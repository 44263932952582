import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

//  MEETINGS

const useMeetings = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAllMeetings = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}meetings`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  }, [getAccessTokenSilently]);

  const createMeetingById = useCallback(
    async ({
      creatorId,
      participantId,
      meetingName,
      meetingDate,
      meetingTime,
      meetingDuration,
      meetingDescription,
      meetingLink,
    }) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}meetings/`,
        data: {
          creatorId: creatorId,
          participantId: participantId,
          meetingName: meetingName,
          meetingDate: meetingDate,
          meetingTime: meetingTime,
          meetingDuration: meetingDuration,
          meetingDescription: meetingDescription,
          meetingLink: meetingLink,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    [getAccessTokenSilently]
  );

  const getMeetingsByCreatorId = useCallback(
    async (creatorId) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}meetings/creator/${creatorId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  const getMeetingsByParticipantId = useCallback(
    async (participantId) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}meetings/participant/${participantId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  const getMeetingsByMeetingId = useCallback(
    async (meetingId) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}meetings/${meetingId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  const deleteMeeting = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();
      await axios.delete(`${process.env.REACT_APP_API_URL}meetings/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    [getAccessTokenSilently]
  );

  const acceptMeeting = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}meetings/${id}/accept`,
        headers: { Authorization: `Bearer ${token}` },
        data: {
          meetingAccepted: true,
        },
      });
    },
    [getAccessTokenSilently]
  );

  const declineMeeting = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}meetings/${id}/accept`,
        headers: { Authorization: `Bearer ${token}` },
        data: {
          meetingAccepted: false,
        },
      });
    },
    [getAccessTokenSilently]
  );

  return {
    getAllMeetings,
    createMeetingById,
    getMeetingsByCreatorId,
    getMeetingsByParticipantId,
    getMeetingsByMeetingId,
    deleteMeeting,
    acceptMeeting,
    declineMeeting,
  };
};

export default useMeetings;
