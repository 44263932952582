import Navbar from "../_navbar/Navbar";
import { useEffect, useState } from "react";
import { Fab, Grid } from "@mui/material";
import { Avatar, Button } from "@mui/material";
import "./Home.css";
import AppWidgetSummary from "../../Widgets/AppWidgetSummary";
import DailySchedule from "../../Widgets/DailySchedule";
import useUsers from "../../api/users";
import { useAuth0 } from "@auth0/auth0-react";
import Popup from "../popup/popup";
import MeetingTable from "../MeetingTable/MeetingTable";
import New_meeting_popup from "../popup/New_meeting_popup";
import useMeetings from "../../api/meetings";

export default function Home({ Id }) {
  const getUserById = useUsers();
  const auth = useAuth0();
  console.log(auth);
  const { isAuthenticated, user } = auth;
  console.log(user);
  const { name, picture, givenName } = user;
  const { createUser, getAllUsers, getByAuth0Id } = useUsers();
  const { createMeetingById, getAllMeetings } = useMeetings();

  const { getAccessTokenSilently } = useAuth0();

  const [DBuser, setDBuser] = useState({});

  const [clickedAddNewMeeting, setClickedAddNewMeeting] = useState(false);

  const insertName = async (firstName, lastName) => {
    await createUser({ firstName, lastName, email: user.email });
    const userId = await getByAuth0Id(token).id;
    setDBuser({
      id: userId,
      firstName,
      lastName,
      email: user.email,
    });
    console.log("DBuser: HIHIHIHIAAAA ", DBuser);
    window.location.reload(false);
  };

  const checkIfAuth0IdExists = async () => {
    const token = await getAccessTokenSilently();
    const res = await getByAuth0Id(token);
    if (!res) {
      console.log("User does not exist");
      setDBuser({ askname: true });
    } else {
      console.log("res: ", res);
      setDBuser(res);
    }
  };

  const closePopUp = () => {
    setClickedAddNewMeeting(false);
  };

  const addMeeting = async (meeting) => {
    console.log("meeting: ", meeting);
    console.log("Meeting Date is: ", { meetingDate: meeting.meetingDate.$d });
    console.log("dbuser id is: ", DBuser.id);
    createMeetingById({
      creatorId: DBuser.id,
      participantId: meeting.participantId,
      meetingName: meeting.meetingName,
      meetingDate: meeting.meetingDate.$d,
      meetingTime: `${meeting.meetingTime.$H}:${meeting.meetingTime.$m}`,
      meetingDuration: `${meeting.meetingDuration.$H}:${meeting.meetingDuration.$m}`,
      meetingDescription: meeting.meetingDescription,
      meetingLink: meeting.meetingLink,
    });
  };

  useEffect(() => {
    console.log("Ik doe ff useeffect");
    checkIfAuth0IdExists();
  }, []);

  return (
    <div className="mainDiv notCentered">
      <Navbar />
      <div className="mainDiv notCentered">
        <Grid className="mainDiv notCentered" container spacing={2}>
          <Grid className="title" item xs={12}>
            <h1>Welcome back {DBuser.firstName}</h1>
          </Grid>
          <Grid className="container" item xs={6}>
            <Avatar src={picture} />
            <p className="peepee">{`${DBuser.firstName} ${DBuser.lastName}`}</p>
          </Grid>
          <Grid className="container eventcreator" item xs={6}>
            <Fab
              variant="extended"
              className="newEvent"
              onClick={() => {
                console.log("click");
                setClickedAddNewMeeting(true);
              }}
            >
              {clickedAddNewMeeting ? (
                <New_meeting_popup
                  open={true}
                  close={closePopUp}
                  addMeeting={addMeeting}
                ></New_meeting_popup>
              ) : (
                <></>
              )}
              + Schedule a new event
            </Fab>
          </Grid>
          <Grid className="padbottom" item xs={12}>
            <hr className="divider" />
          </Grid>
          <Grid className="padbottom" item xs={12}>
            {DBuser.askname === true ? (
              <Popup open={true} dothis={insertName}></Popup>
            ) : (
              <></>
            )}
          </Grid>
          <Grid className="padbottom" item xs={12}>
            <h3>Here's todays schedule</h3>
          </Grid>
          <Grid className="kalend dailysched" item xs={12}>
            <DailySchedule />
          </Grid>
          <Grid className="padbottom padtop" item xs={12}>
            <h3>Recent requests</h3>
          </Grid>
          <Grid className="padbottom" item xs={12}>
            {console.log("Kankerbolle", DBuser.id)}
            {DBuser.id ? <MeetingTable userId={DBuser.id} /> : <></>}
            {/* <Grid className="padtop" item xs={4}>
              <AppWidgetSummary
                title="Booked meetings"
                total={5}
                icon={"ant-design:schedule-outlined"}
                className="rounded"
              />*/}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
