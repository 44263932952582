import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import "./EventCard.css";
import useMeetings from "../../api/meetings";
import { useState, useEffect } from "react";
import { getUserById } from "../../api/users";
import useUsers from "../../api/users";

export default function EventCard({ meetingID }) {
  const { getMeetingsByMeetingId, deleteMeeting } = useMeetings();
  const { getUserById } = useUsers();

  console.log("@eventcard meetingid= ", meetingID);
  console.log("@eventcard meetingID = ", meetingID);
  const [meetingObj, setMeeting] = useState({
    meetingID: 0,
    creatorId: 1,
    participantId: 1,
    meetingName: "sample meeting",
    meetingDate: "1900-01-01",
    meetingTime: "00:00",
    meetingDuration: "1:00",
    meetingDescription: "sample meeting",
    meetingLink: "https://zoom.us/",
  });
  const [participantName, setparticipantName] = useState("noname");

  useEffect(() => {
    console.log("UseEffect is running in eventcard");
    const fetchMeeting = async (meetingId) => {
      const res = await getMeetingsByMeetingId(meetingId);
      console.log("@eventcard res =", res);
      setMeeting(res);
      console.log("@eventcard meetingObj =", meetingObj);
    };
    fetchMeeting(meetingID);
    const getUserNameById = async (userId) => {
      const res = await getUserById(userId);
      setparticipantName(res.firstName + " " + res.lastName);
    };
    getUserNameById(meetingObj.participantId);
  }, []);
  let meetingName = "";
  let meetingDate = new Date("1900-01-01");
  let meetingTime = "";
  let meetingDuration = "";
  let meetingDescription = "";
  let meetingLink = "";
  if (meetingObj !== {}) {
    meetingName = meetingObj.meetingName;
    meetingDate = new Date(meetingObj.meetingDate);
    meetingTime = meetingObj.meetingTime;
    meetingDuration = meetingObj.meetingDuration;
    meetingDescription = meetingObj.meetingDescription;
    meetingLink = meetingObj.meetingLink;
    console.log(meetingObj);
  }

  // write a function to get the day of the week from the date
  const getDayOfWeek = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek)
      ? null
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][dayOfWeek];
  };
  //write a function to get the date in format of day month year
  const getFormattedDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  };

  const addtime = (time, duration) => {
    let [hour, minute] = time.split(":");
    minute = parseInt(minute) + parseInt(duration);
    hour = parseInt(hour) + Math.floor(minute / 60);
    minute = minute % 60;
    hour = hour % 24;
    return `${hour}:${minute}`;
  };

  return (
    <>
      <Card className="card">
        <Box sx={{ p: 2, display: "flex", pb: 4 }}>
          <Stack spacing={1}>
            <Typography
              className="txtBox notCentered"
              fontSize={20}
              fontWeight={700}
            >
              {meetingName}
            </Typography>
            <Typography
              className="txtBox notCentered"
              fontSize={16}
              fontWeight={500}
            >
              Meeting with {participantName}
            </Typography>
            <Typography
              className="txtBox notCentered"
              fontSize={15}
              fontWeight={500}
            >
              {getDayOfWeek(meetingDate)}, {getFormattedDate(meetingDate)},{" "}
              {meetingTime} to {addtime(meetingTime, meetingDuration)}
            </Typography>
            <Typography
              className="txtBox notCentered"
              variant="body2"
              color="text.secondary"
            >
              {meetingDescription}
            </Typography>
          </Stack>
          <IconButton>
            <Edit sx={{ fontSize: 14 }} />
          </IconButton>
        </Box>
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          <IconButton sx={{ bgcolor: "red" }}>
            <DeleteForeverRoundedIcon
              onClick={() => {
                console.log("delete button clicked");
                deleteMeeting(meetingID);
              }}
            />
          </IconButton>
          <Switch />
        </Stack>
      </Card>
    </>
  );
}
