import React from "react";
import { Link } from "react-router-dom";
import AuthenticationButton from "../../authentication/AuthenticationButton";
import "./headers.css";
import logo from "../../content/profile2.png";

const Navitem = ({ destination, label }) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={destination}>
        {label}
      </Link>
    </li>
  );
};
export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} className="navbarLogo" alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse notCentered justStayCenteredYouDumbNavbar"
          id="navbarCollapse"
        >
          <ul className="navbar-nav me-auto mb-2 mb-md-0 notCentered">
            <Navitem destination="/" label="Home" />
            <Navitem destination="/dashboard" label="Dashboard" />
            <Navitem destination="/contact" label="Contact" />
          </ul>
          <AuthenticationButton />
        </div>
      </div>
    </nav>
  );
}
