import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { useState } from "react";
import "./popup.css";

const OverlayPopupFeedback = ({ open, dothis }) => {
  const [modal, setModal] = useState(open);

  const toggleModal = () => {
    console.log("I'm toggling");
    dothis(
      document.getElementById("firstname").value,
      document.getElementById("lastname").value
    );
    setModal(!modal);
    window.location.reload(false);
  };

  return (
    <div>
      {toggleModal}
      <Modal className="mainPopup" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Please provide your firstname and lastname
        </ModalHeader>
        <ModalBody>
          First name
          <Input
            className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
            type="text"
            placeholder="Ex: John"
            name="firstname"
            id="firstname"
          />
          Last name
          <Input
            className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
            type="text"
            placeholder="Ex: Doe"
            name="lastname"
            id="lastname"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="centerthisbutton"
            color="primary"
            onClick={toggleModal}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default OverlayPopupFeedback;
