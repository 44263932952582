import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { useState } from "react";
import "./popup.css";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from "dayjs";
import "./popup.css";
import useUsers from "../../api/users";
import { useEffect } from "react";
import { FormControl } from "@mui/material";

const OverlayPopupFeedback = ({ open, close, addMeeting }) => {
  const [modal, setModal] = useState(open);
  const [selection, setSelection] = useState("");
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [TimeValue, setTimeValue] = React.useState(dayjs(new Date()));
  const [DurationValue, setDurationValue] = React.useState(dayjs(new Date()));
  const [userList, setUserList] = useState([]);

  const toggleModal = () => {
    setModal(!modal);
    close();
  };
  const toggleModalSubmit = () => {
    addMeeting({
      participantId: selection,
      meetingName: document.getElementById("meetingname").value,
      meetingDate: value,
      meetingTime: TimeValue,
      meetingDuration: DurationValue,
      meetingDescription: document.getElementById("description").value,
      meetingLink: document.getElementById("link").value,
    });
    setModal(!modal);
    close();
  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handlTimeChange = (newValue) => {
    setTimeValue(newValue);
  };
  const handleDurationChange = (newValue) => {
    setDurationValue(newValue);
  };
  const { getAllUsersIdAndName } = useUsers();

  useEffect(() => {
    const getUserList = async () => {
      setUserList(await getAllUsersIdAndName());
    };
    getUserList();
  }, []);

  return (
    <div>
      {toggleModal}
      <Modal className="mainPopup" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Please provide your firstname and lastname
        </ModalHeader>
        <ModalBody>
          Participant <br />
          <FormControl error>
            <select
              value={selection}
              onChange={(e) => setSelection(e.target.value)}
              className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
              id="participant"
            >
              {userList.map((item) => (
                <option key={item.id} value={item.id}>
                  {`${item.firstName} ${item.lastName}`}
                </option>
              ))}
            </select>
          </FormControl>
          <br />
          Meeting Name
          <br />
          <Input
            className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
            type="text"
            placeholder="Ex: Business Meeting"
            name="meetingname"
            id="meetingname"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            Date
            <br />
            <DesktopDatePicker
              value={value}
              className="paddingontop"
              inputFormat="DD/MM/YYYY"
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />{" "}
            <br />
            Time
            <br />
            <TimePicker
              value={TimeValue}
              className="paddingontop"
              onChange={handlTimeChange}
              renderInput={(params2) => <TextField {...params2} />}
            />
            <br />
            Duration
            <br />
            <TimePicker
              value={DurationValue}
              className="paddingontop"
              onChange={handleDurationChange}
              renderInput={(params3) => <TextField {...params3} />}
            />
            <br />
            Description
            <br />
            <Input
              className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
              type="text"
              placeholder="Ex: This meeting is about the numbers of Q4\
              "
              name="description"
              id="description"
            />
            <br />
            Link
            <br />
            <Input
              className="stringInputs w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal"
              type="text"
              placeholder="(optional)"
              name="link"
              id="link"
            />
            <br />
          </LocalizationProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            className="centerthisbutton"
            color="primary"
            onClick={toggleModalSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default OverlayPopupFeedback;
