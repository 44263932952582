import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

//  USERS

const useUsers = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getAllUsers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    console.log("gay ass token:", token);
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  }, [getAccessTokenSilently]);

  const getAllUsersIdAndName = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}users/idAndName`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  }, [getAccessTokenSilently]);

  const getUserById = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}users/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  const getByEmail = useCallback(
    async (email) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}users/email/${email}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  const createUser = useCallback(
    async ({ firstName, lastName, email }) => {
      const token = await getAccessTokenSilently();
      console.log("repo data:", firstName, lastName, email);

      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}users`,
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    [getAccessTokenSilently]
  );

  const updateUserById = useCallback(
    async ({ id, firstName, lastName, email, password, isAdmin }) => {
      const token = await getAccessTokenSilently();

      const { data } = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}users/${id}`,
        data: {
          firstName,
          lastName,
          email,
          password,
          auth0id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    [getAccessTokenSilently]
  );

  const deleteUser = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();

      await axios.delete(`${process.env.REACT_APP_API_URL}users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    [getAccessTokenSilently]
  );
  const getByAuth0Id = useCallback(
    async (id) => {
      const token = await getAccessTokenSilently();
      console.log(token);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}users/auth0`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data;
    },
    [getAccessTokenSilently]
  );

  return {
    getAllUsers,
    getUserById,
    getByEmail,
    createUser,
    updateUserById,
    deleteUser,
    getByAuth0Id,
    getAllUsersIdAndName,
  };
};

export default useUsers;
