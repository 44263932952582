import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

export default function AuthenticationButton() {
  const { isAuthenticated, user } = useAuth0(); // 👈 1

  if (isAuthenticated) {
    // 👈 2
    const { name, picture, givenName } = user;
    return (
      <div className="d-flex flex-row align-items-center navbarUserinfo">
        <div className="col navbarUserinfo">
          <img
            src={picture}
            alt={givenName}
            className="rounded navbarUserinfoPicture"
          />
        </div>
        <div className="col navbarUserinfo">{name}</div>
        <div className="col navbarUserinfo">
          <LogoutButton className="navbarUserinfo" />
        </div>
      </div>
    );
  }

  return <LoginButton />;
}
