import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import useMeeting from "../../api/meetings";
import { useEffect, useState } from "react";
import useUsers from "../../api/users";
import Swal from "sweetalert2";

// createData(
//   "Sample meeting",
//   "Daddy",
//   "12-12-2022",
//   "12:00",
//   "1:30",
//   "This is a sample meeting man"
// ),
// createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),

function createData(id, name, withPerson, date, time, duration, description) {
  return {
    id,
    name,
    withPerson,
    date,
    time,
    duration,
    description,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  const meetingApi = useMeeting();

  const acceptMeeting = async (id) => {
    await meetingApi.acceptMeeting(id);
  };

  const declineMeeting = async (id) => {
    await meetingApi.declineMeeting(id);
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="removePadding">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon className="centerIcon" />
            ) : (
              <KeyboardArrowDownIcon className="centerIcon" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="removePaddingLeft">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.withPerson}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.time}</TableCell>
        <TableCell align="right">{row.duration}</TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              acceptMeeting(row.id);
              Swal.fire("Successful!", "", "success").then(() => {
                window.location.reload();
              });
            }}
          >
            Accept
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              declineMeeting(row.id);
              Swal.fire("Successful!", "", "success").then(() => {
                window.location.reload();
              });
            }}
          >
            Refuse
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="maxwidthTC">
              <Typography variant="h6" gutterBottom component="div">
                Description
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p>{row.description}</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  //ff
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    withPerson: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

// let rows = [
//   createData(
//     69,
//     "Sample meeting",
//     "Daddy",
//     "12-12-2022",
//     "12:00",
//     "1:30",
//     "This is a sample meeting man"
//   ),
//   createData(
//     39,
//     "Sample meeting",
//     "Daddy",
//     "12-12-2022",
//     "12:00",
//     "1:30",
//     "This is a sample meeting man"
//   ),
//   createData(
//     12,
//     "Sample meeting",
//     "Daddy",
//     "12-12-2022",
//     "12:00",
//     "1:30",
//     "This is a sample meeting man"
//   ),
//   createData(
//     13,
//     "Sample meeting",
//     "Daddy",
//     "12-12-2022",
//     "12:00",
//     "1:30",
//     "This is a sample meeting man"
//   ),
// ];

export default function CollapsibleTable(UserId) {
  console.log("nen fluit", UserId);
  const [userId, setUserId] = useState(UserId.userId);
  const { getMeetingsByParticipantId } = useMeeting();
  const [rows, setRows] = useState([]);
  const insertDataInRows = async () => {
    console.log("this is the user id: ", UserId.userId);

    const meetings = await getMeetingsByParticipantId(userId);
    console.log("this a test: ", meetings);
    let allrows = [];
    meetings.forEach((meeting) => {
      console.log("Meetingaceepted", meeting.meetingAccepted);
      if (meeting.meetingAccepted == null) {
        allrows.push(
          createData(
            meeting.meetingID,
            meeting.meetingName,
            `${meeting.creatorFirstName} ${meeting.creatorLastName}`,
            meeting.meetingDate,
            meeting.meetingTime,
            meeting.meetingDuration,
            meeting.meetingDescription
          )
        );
      }
    });
    setRows(allrows);
  };

  useEffect(() => {
    setUserId(UserId.userId);
    insertDataInRows();
    console.log("yoo");
    console.log(rows);
  }, []);

  if (rows.length === 0) {
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className="removePaddingLeft">Event</TableCell>
                <TableCell align="left">With</TableCell>
                <TableCell align="left">Date&nbsp;</TableCell>
                <TableCell align="left">Time&nbsp;</TableCell>
                <TableCell align="left">Duration&nbsp;</TableCell>
                <TableCell align="left">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody> </TableBody>
          </Table>
          <h3 className="mainCenter">No meeting requests at this moment</h3>
        </TableContainer>
      </>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="removePaddingLeft">Event</TableCell>
            <TableCell align="right">With</TableCell>
            <TableCell align="right">Date&nbsp;</TableCell>
            <TableCell align="right">Time&nbsp;</TableCell>
            <TableCell align="right">Duration&nbsp;</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
