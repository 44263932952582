import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { Eventcalendar, getJson, toast } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import React, { useState } from "react";
import Kalend, {
  CalendarView,
  OnEventClickData,
  OnNewEventClickData,
  ShowMoreMonthData,
  OnPageChangeData,
  OnSelectViewData,
} from "kalend"; // import component
import "kalend/dist/styles/index.css"; // import styles
import { useEffect } from "react";
import { color } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import useMeeting from "../api/meetings";
import useUsers from "../api/users";

export default function App() {
  const { getAccessTokenSilently } = useAuth0();
  const [myEvents, setEvents] = useState({
    start: "2022-11-28T08:00:00.000Z",
    end: "2022-12-01T17:00:00.000Z",
    title: "Business of Software Conference",
    color: "#ff6d42",
  });
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    getEvents();
    console.log("EFFE UITVOERE MAN");
  }, []);

  let events = [];

  const getUserId = async () => {
    const token = await getAccessTokenSilently();
    const schijt = await getByAuth0Id();
    console.log("Schijt: ", schijt.id);
    return schijt.id;
  };

  const { getMeetingsByParticipantId, getMeetingsByMeetingId } = useMeeting();
  const { getByAuth0Id } = useUsers();

  const getEvents = async () => {
    const userId = await getUserId();
    const result = await getMeetingsByParticipantId(userId);
    console.log(
      "this a test2: ",
      await getMeetingsByParticipantId(userId),
      userId
    );

    //for each item in the result, create an event
    Date.prototype.addHours = function (h) {
      this.setHours(this.getHours() + h);
      return this;
    };
    for (let index = 0; index < result.length; index++) {
      const numba =
        Number(result[index].meetingTime.substring(3)) +
        Number(result[index].meetingDuration.substring(3));

      const endAt = new Date(result[index].meetingDate).setHours(
        Number(result[index].meetingTime.substring(0, 2)) +
          Number(result[index].meetingDuration.substring(0, 2)),
        Number(result[index].meetingTime.substring(3)) +
          Number(result[index].meetingDuration.substring(3))
      );
      console.log("end at: ", numba);

      events.push(
        await createData(
          result[index].meetingID,
          new Date(result[index].meetingDate).setHours(
            result[index].meetingTime.substring(0, 2),
            result[index].meetingTime.substring(2)
          ),
          endAt,
          result[index].meetingDescription
        )
      );
      console.log("Yoooo: ", typeof result[index].meetingDate);
      console.log("YOPU JDFBSOFGHDSOHGFOISHJFDO:", events);
    }

    //   result.map((meeting) => {
    //     const endAt = new Date(
    //       meeting.meetingDate.addHours(
    //         number(meeting.meetingTime.substring(0, 2))
    //       )
    //     ).setMinutes(
    //       meeting.meetingDate.getMinutes() +
    //         number(meeting.meetingDuration.substring(2, 4))
    //     );
    //     events.push(
    //       createData(
    //         meeting.meetingID,
    //         meeting.meetingDate,
    //         endAt,
    //         meeting.meetingDescription
    //       )
    //     );
    //     console.log("this is the event: ", events);
    //   });
  };

  const onEventClick = React.useCallback((event) => {
    toast({
      message: event.event.title,
    });
  }, []);

  const createData = async (id, startAt, endAt, summary) => {
    const meeting = await getMeetingsByMeetingId(id);
    let color = "grey";
    if (meeting.meetingAccepted === null) {
      color = "blue";
    } else if (meeting.meetingAccepted === true) {
      color = "green";
    } else if (meeting.meetingAccepted === false) {
      color = "red";
    }
    return {
      id: id,
      startAt: startAt,
      endAt: endAt,
      summary: summary,
      color: color,
    };
  };

  const view = React.useMemo(() => {
    return {
      schedule: { type: "day" },
    };
  }, []);
  const events2 = [
    {
      id: 1,
      startAt: "2022-12-05T18:00:00.000Z",
      endAt: "2022-12-05T19:12:00.000Z",
      summary: "test",
      color: "blue",
    },
    {
      id: 2,
      startAt: "2022-12-05T13:13:00.000Z",
      endAt: "2022-12-05T15:15:00.000Z",
      summary: "test",
      color: "blue",
    },
  ];

  return (
    <>
      {console.log("this is the event: ", events)}
      <Kalend
        onEventClick={onEventClick}
        //onNewEventClick={onNewEventClick}
        events={[...events]}
        initialDate={new Date().toISOString()}
        hourHeight={30}
        initialView={CalendarView.THREE_DAYS}
        disabledViews={[]}
        //onSelectView={onSelectView}
        //selectedView={selectedView}
        //onPageChange={onPageChange}
        timeFormat={"24"}
        weekDayStart={"Monday"}
        calendarIDsHidden={["work"]}
        language={"en"}
        showTimeLine={true}
        isDark={false}
        autoScroll={true}
      />
    </>
  );
}
