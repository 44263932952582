import Navbar from "../_navbar/Navbar";
import "./Dashboard.css";
import { useEffect, useState } from "react";
import EventCard from "../EventCard/EventCard";
import useMeetings from "../../api/meetings";
import useUsers from "../../api/users";
import { useAuth0 } from "@auth0/auth0-react";

export default function Dashboard({ userId }) {
  const [meetingIDs, setMeetingIDs] = useState([]);
  const { getMeetingsByCreatorId } = useMeetings();

  const auth = useAuth0();
  const { user } = auth;
  const { getByAuth0Id } = useUsers();

  const getUser = async () => {
    const res = await getByAuth0Id(user.sub);
    return res;
  };

  const getMeetingIDsByCreatorId = async () => {
    const DBuser = await getUser();
    console.log("@dashboard user: ", DBuser.id);
    const res = await getMeetingsByCreatorId(DBuser.id);
    let meetingIDS = [];
    res.forEach((meeting) => {
      meetingIDS.push(meeting.meetingID);
    });
    console.log("@dashboard meetingIDS: ", meetingIDS);
    return meetingIDS;
  };

  useEffect(() => {
    const doekeergoe = async () => {
      setMeetingIDs(await getMeetingIDsByCreatorId());
    };
    doekeergoe();
    console.log("meetingIDs: ", meetingIDs);
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <div className="kontainer">
          {meetingIDs.length === 0 ? (
            <h1>You have no meetings scheduled</h1>
          ) : (
            meetingIDs.map((meetingID) => {
              return <EventCard meetingID={meetingID} key={meetingID} />;
            })
          )}
        </div>
      </div>
    </>
  );
}
