import "./App.css";
import React, { useState } from "react";
import SignIn from "./components/sign-in-page/Sign-In";
import { Routes, Route, Navigate, useActionData } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from "./components/Home/Home";
import Viernulvier from "./components/404/404";
import RequireAuth from "./authentication/RequireAuth";
import AuthLanding from "../src/authentication/AuthLanding";
import useMeetings from "./api/meetings";
import useUsers from "./api/users";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticationButton from "./authentication/AuthenticationButton";

function App() {
  const { isAuthenticated, user } = useAuth0();
  // const [user, setUser] = useState({ id: 0 });
  // const [error, setError] = useState("");
  // const { getByEmail } = useUsers();

  // const checkLogin = async (email, password) => {
  //   try {
  //     const res = await getByEmail(email);
  //     console.log(res);
  //     if (res.password === password) {
  //       setUser({
  //         id: res.id,
  //       });
  //       return res.id;
  //     } else {
  //       return 0;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setError(error);
  //   }
  // };

  // const Login = (details) => {
  //   if (checkLogin(details.email, details.password) !== 0) {
  //     console.log("Logged In");
  //     console.log(user);
  //   }
  // };

  // const Logout = () => {
  //   setUser({ name: "", email: "" });
  // };
  // const paths = ["/dashboard", "/home", "/", "/404"];
  // const checkPath = () => {
  //   const pathname = window.location.pathname;
  //   if (!paths.includes(pathname)) {
  //     return <Viernulvier />;
  //   } else
  //     return (
  //       <div className="centered">
  //         <SignIn Login={Login} error={error} />
  //       </div>
  //     );
  // };
  return (
    <div className="App">
      <div className="welcome">
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<AuthLanding />} />
          <Route path="*" element={<Viernulvier />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
