import "./404.css";
import { Button } from "@mui/material";
import gif from "./giphy.gif";

export default function viernulvier() {
  return (
    <>
      <div className="viernulvier divi">
        <img src={gif} alt="loading..." />
      </div>
      <div className="viernulvier divi padtop">
        <Button variant="filled" className="newEvent" href="/">
          Go back
        </Button>
      </div>
    </>
  );
}
